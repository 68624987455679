(function(f){if(typeof exports==="object"&&typeof module!=="undefined"){module.exports=f()}else if(typeof define==="function"&&define.amd){define([],f)}else{var g;if(typeof window!=="undefined"){g=window}else if(typeof global!=="undefined"){g=global}else if(typeof self!=="undefined"){g=self}else{g=this}g.epPresentations = f()}})(function(){var define,module,exports;return (function e(t,n,r){function s(o,u){if(!n[o]){if(!t[o]){var a=typeof require=="function"&&require;if(!u&&a)return a(o,!0);if(i)return i(o,!0);var f=new Error("Cannot find module '"+o+"'");throw f.code="MODULE_NOT_FOUND",f}var l=n[o]={exports:{}};t[o][0].call(l.exports,function(e){var n=t[o][1][e];return s(n?n:e)},l,l.exports,e,t,n,r)}return n[o].exports}var i=typeof require=="function"&&require;for(var o=0;o<r.length;o++)s(r[o]);return s})({1:[function(require,module,exports){
var Assessment, CollapsiblePanel, PureRenderMixin, Subgroup;

Subgroup = require('./subgroups/subgroup');

CollapsiblePanel = epReactComponents.common.CollapsiblePanel;

PureRenderMixin = React.addons.PureRenderMixin;

Assessment = React.createClass({displayName: "Assessment",
  mixins: [PureRenderMixin],
  _getResearchEvidenceIndex: function(index) {
    if (index === 'undesirableEffects' || index === 'desirableEffects' || index === 'certaintyOfEvidence') {
      return 'effects';
    } else {
      return index;
    }
  },
  render: function() {
    var sections;
    sections = this.props.assessmentSections;
    return React.createElement("div", {
      "className": "assessment"
    }, sections.map((function(_this) {
      return function(section, index) {
        return React.createElement(CollapsiblePanel, {
          "title": section.get('name'),
          "className": "panel-toggle block",
          "collapsed": true
        }, React.createElement("div", {
          "className": "description"
        }, _this.props.assessment.get('criteria').get(index).get('description')), React.createElement("div", null, React.createElement(Subgroup, {
          "index": index,
          "attachments": _this.props.attachments,
          "actions": _this.props.actions,
          "currentEditable": _this.props.currentEditable,
          "criteria": _this.props.assessment.get('criteria').get(index),
          "researchEvidences": _this.props.assessment.get('researchEvidences').get(_this._getResearchEvidenceIndex(index)),
          "additionalConsiderations": _this.props.assessment.get('additionalConsiderations').get(index)
        })));
      };
    })(this)));
  }
});

module.exports = Assessment;


},{"./subgroups/subgroup":27}],2:[function(require,module,exports){
var Background, Button, CollapsiblePanel, ContentEditableWithButtons, LabelWithContentEditbale, LabelWithInput, LabelWithText, MainOutcomes, StaticHtmlContent,
  indexOf = [].indexOf || function(item) { for (var i = 0, l = this.length; i < l; i++) { if (i in this && this[i] === item) return i; } return -1; };

LabelWithText = epReactComponents.common.LabelWithText;

MainOutcomes = require('./common/main_outcomes');

Button = epReactComponents.common.Button;

LabelWithInput = require('./common/label_with_input');

LabelWithContentEditbale = require('./common/label_with_contenteditable');

ContentEditableWithButtons = epReactComponents.common.ContentEditableWithButtons;

CollapsiblePanel = epReactComponents.common.CollapsiblePanel;

StaticHtmlContent = require('./common/static_html_content');

Background = React.createClass({displayName: "Background",
  getInitialState: function() {
    return {
      editing: false
    };
  },
  updateProp: function(attr, value) {
    return this.props.actions.updateBackground({
      'attr': attr,
      'value': value
    });
  },
  handleUserInput: function(attr) {
    return this.updateProp(attr.sectionId, attr.content);
  },
  _prepareItems: function(section, index) {
    var editable, hasHtmlContent, htmlContent, notEditable;
    if (index === 'background') {
      return;
    }
    notEditable = ['patients', 'intervention', 'comparison', 'mainOutcomes', 'purpose', 'population', 'linkedTreatments', 'anticipatedOutcomes'];
    htmlContent = ['perspective', 'setting'];
    editable = this.props.isEditable && this.state.editing && indexOf.call(notEditable, index) < 0;
    hasHtmlContent = indexOf.call(htmlContent, index) >= 0;
    if (hasHtmlContent) {
      return React.createElement(LabelWithContentEditbale, {
        "label": section.get('name'),
        "ref": "background-" + index,
        "name": "background-" + index,
        "value": section.get('content'),
        "isEditable": editable,
        "handleUserInput": this.handleUserInput
      });
    } else if (editable) {
      return React.createElement(LabelWithInput, {
        "ref": index,
        "name": "background-" + index,
        "label": section.get('name'),
        "value": section.get('content'),
        "onChange": this.updateProp
      });
    } else {
      return React.createElement(LabelWithText, {
        "classes": index,
        "title": section.get('name'),
        "value": section.get('content')
      });
    }
  },
  renderBackgroundPolicymakers: function() {
    var ref;
    return React.createElement("div", null, React.createElement("div", {
      "className": "pull-right clearfix"
    }, (this.props.isEditable ? this.state.editing ? React.createElement(Button, {
      "className": "save",
      "onClick": this.save
    }) : React.createElement(Button, {
      "className": "edit",
      "onClick": this.toggleEditMode
    }) : void 0)), React.createElement("div", {
      "className": "background"
    }, React.createElement(CollapsiblePanel, {
      "title": this.props.sections.get('background').get('description'),
      "className": "panel-toggle block"
    }, this.props.question.map((function(_this) {
      return function(section, index) {
        if (index === 'mainOutcomes' || index === 'anticipatedOutcomes') {
          return React.createElement(MainOutcomes, {
            "title": section.get('name'),
            "value": section.get('content')
          });
        } else {
          return _this._prepareItems(section, index);
        }
      };
    })(this))), (this.props.backgroundDetailSections ? React.createElement(CollapsiblePanel, {
      "title": (ref = this.props.sections.get('background').get('details')) != null ? ref.get('name') : void 0,
      "className": "panel-toggle block"
    }, this.props.backgroundDetailSections.map((function(_this) {
      return function(section, index) {
        if (_this.props.isEditable && _this.state.editing) {
          return React.createElement(LabelWithInput, {
            "ref": index,
            "name": "details-" + index,
            "label": section.get('description'),
            "value": section.get('content'),
            "onChange": _this.updateProp
          });
        } else {
          return React.createElement(LabelWithText, {
            "classes": index,
            "title": section.get('description'),
            "value": section.get('content')
          });
        }
      };
    })(this))) : void 0)));
  },
  save: function() {
    this.props.actions.saveBackground();
    return this.setState({
      editing: false
    });
  },
  toggleEditMode: function() {
    return this.setState({
      editing: true
    });
  },
  render: function() {
    var contentClasses;
    contentClasses = classNames('content-bordered', {
      'editable': this.props.isEditable
    });
    return React.createElement("div", null, (this.props.type === 'policymakers' ? this.renderBackgroundPolicymakers() : void 0), React.createElement("h4", {
      "className": "section-title"
    }, this.props.question.get('background').get('name')), React.createElement("div", {
      "className": contentClasses
    }, (this.props.isEditable ? React.createElement(ContentEditableWithButtons, {
      "actions": this.props.actions,
      "currentEditable": this.props.currentEditable,
      "id": "background#question",
      "content": this.props.question.get('background').get('content')
    }) : React.createElement(StaticHtmlContent, {
      "content": this.props.question.get('background').get('content')
    }))));
  }
});

module.exports = Background;


},{"./common/label_with_contenteditable":8,"./common/label_with_input":9,"./common/main_outcomes":10,"./common/static_html_content":13}],3:[function(require,module,exports){
var Clinicians, SectionTabs;

SectionTabs = require('../common/section_tabs');

Clinicians = React.createClass({displayName: "Clinicians",
  render: function() {
    return React.createElement(SectionTabs, {
      "currentEditable": this.props.currentEditable,
      "actions": this.props.actions,
      "isEditable": this.props.isEditable,
      "question": this.props.question,
      "sections": this.props.sections,
      "conclusions": this.props.conclusions,
      "isofData": this.props.isofData,
      "defaultTab": 'background',
      "type": "clinicians"
    });
  }
});

module.exports = Clinicians;


},{"../common/section_tabs":12}],4:[function(require,module,exports){
var AdditionalConsiderations, StaticHtmlContent;

StaticHtmlContent = require('./static_html_content');

AdditionalConsiderations = React.createClass({displayName: "AdditionalConsiderations",
  render: function() {
    return React.createElement(StaticHtmlContent, {
      "content": this.props.content
    });
  }
});

module.exports = AdditionalConsiderations;


},{"./static_html_content":13}],5:[function(require,module,exports){
var Decision, RadioInput;

RadioInput = epReactComponents.common.RadioInput;

Decision = React.createClass({displayName: "Decision",
  render: function() {
    return React.createElement("div", {
      "className": "detailed decision"
    }, React.createElement("div", {
      "className": "radio-buttons"
    }, React.createElement("ul", null, this.props.criteria.get('options').map((function(_this) {
      return function(option) {
        return React.createElement("li", {
          "key": option.get('value')
        }, _this.getInput(option));
      };
    })(this)), React.createElement("li", {
      "className": "additional-options-separator"
    }), this.props.criteria.get('additionalOptions').map((function(_this) {
      return function(option) {
        return React.createElement("li", {
          "key": option.get('value')
        }, _this.getInput(option));
      };
    })(this)))));
  },
  decisionChanged: function(e) {
    return this.props.actions.changeAndSaveCriteriaDecision(this.props.sectionId, e.currentTarget.value);
  },
  getInput: function(option) {
    var checked, ref;
    checked = option.get('value') === ((ref = this.props.criteria.get('decision')) != null ? ref.get('selectedOption') : void 0);
    return React.createElement("label", {
      "htmlFor": option.get('value')
    }, React.createElement(RadioInput, {
      "id": option.get('value'),
      "name": this.props.sectionId + "-decision",
      "value": option.get('value'),
      "onChange": this.decisionChanged,
      "defaultChecked": checked
    }), React.createElement("span", null, option.get('text')));
  }
});

module.exports = Decision;


},{}],6:[function(require,module,exports){
var ContentEditableWithButtons, DetailedContentEditable, StaticHtmlContent;

ContentEditableWithButtons = epReactComponents.common.ContentEditableWithButtons;

StaticHtmlContent = require('./static_html_content');

DetailedContentEditable = React.createClass({displayName: "DetailedContentEditable",
  propTypes: {
    actions: React.PropTypes.object.isRequired,
    id: React.PropTypes.string.isRequired,
    currentEditable: React.PropTypes.string.isRequired
  },
  render: function() {
    return React.createElement("div", null, React.createElement("h4", null, this.props.contentLabel), React.createElement(StaticHtmlContent, {
      "content": this.props.content
    }), React.createElement("h4", null, this.props.detailLabel), (this.props.isEditable ? React.createElement(ContentEditableWithButtons, {
      "id": this.props.id,
      "currentEditable": this.props.currentEditable,
      "actions": this.props.actions,
      "content": this.props.detailContent
    }) : React.createElement(StaticHtmlContent, {
      "content": this.props.detailContent
    })));
  }
});

module.exports = DetailedContentEditable;


},{"./static_html_content":13}],7:[function(require,module,exports){
var Judgement;

Judgement = React.createClass({displayName: "Judgement",
  getSelectedText: function() {
    var additionalOptions, options, ref;
    options = this.props.criteria.get('options');
    additionalOptions = this.props.criteria.get('additionalOptions');
    options = options.concat(additionalOptions);
    return (ref = options.find((function(_this) {
      return function(obj) {
        return obj.get('value') === _this.props.criteria.get('selectedOption');
      };
    })(this))) != null ? ref.get('text') : void 0;
  },
  render: function() {
    return React.createElement("div", null, (this.props.criteria.get('selectedOption') ? this.getSelectedText() : ""));
  }
});

module.exports = Judgement;


},{}],8:[function(require,module,exports){
var ContentEditable, LabelWithContentEditable, StaticHtmlContent;

StaticHtmlContent = require('./static_html_content');

ContentEditable = epReactComponents.common.ContentEditable;

LabelWithContentEditable = React.createClass({displayName: "LabelWithContentEditable",
  propTypes: {
    label: React.PropTypes.string,
    name: React.PropTypes.string,
    value: React.PropTypes.string,
    isEditable: React.PropTypes.bool
  },
  handleUserInput: function(e) {
    var content, sectionId;
    content = e.target.innerHTML;
    sectionId = this.props.name;
    return this.props.handleUserInput({
      content: content,
      sectionId: sectionId
    });
  },
  render: function() {
    return React.createElement("div", {
      "className": "label-with-contenteditable"
    }, React.createElement("label", null, this.props.label), (this.props.isEditable ? React.createElement(ContentEditable, {
      "ref": this.props.name,
      "name": this.props.name,
      "html": this.props.value,
      "onInput": this.handleUserInput
    }) : React.createElement(StaticHtmlContent, {
      "content": this.props.value
    })));
  }
});

module.exports = LabelWithContentEditable;


},{"./static_html_content":13}],9:[function(require,module,exports){
var LabelWithInput;

LabelWithInput = React.createClass({displayName: "LabelWithInput",
  propTypes: {
    label: React.PropTypes.string,
    name: React.PropTypes.string,
    value: React.PropTypes.string
  },
  getInitialState: function() {
    return {
      value: this.props.value
    };
  },
  onChange: function(e) {
    return this.props.onChange(e.target.name, e.target.value);
  },
  render: function() {
    return React.createElement("div", {
      "className": "label-with-input"
    }, React.createElement("label", null, this.props.label), React.createElement("input", {
      "ref": this.props.name,
      "type": "text",
      "name": this.props.name,
      "defaultValue": this.state.value,
      "onChange": this.onChange
    }));
  }
});

module.exports = LabelWithInput;


},{}],10:[function(require,module,exports){
var MainOutcomes;

MainOutcomes = React.createClass({displayName: "MainOutcomes",
  createMarkup: function() {
    return {
      __html: this.props.value
    };
  },
  render: function() {
    return React.createElement("div", {
      "className": "main-outcomes"
    }, React.createElement("label", null, this.props.title), React.createElement("div", {
      "className": "main-outcomes-content",
      "dangerouslySetInnerHTML": this.createMarkup()
    }), React.createElement("div", {
      "className": "clearfix"
    }));
  }
});

module.exports = MainOutcomes;


},{}],11:[function(require,module,exports){
var ResearchEvidences, StaticHtmlContent;

StaticHtmlContent = require('./static_html_content');

ResearchEvidences = React.createClass({displayName: "ResearchEvidences",
  render: function() {
    return React.createElement(StaticHtmlContent, {
      "attachments": this.props.attachments,
      "content": this.props.content
    });
  }
});

module.exports = ResearchEvidences;


},{"./static_html_content":13}],12:[function(require,module,exports){
var Assessment, Background, Decision, DetailedJustification, Implementation, Justification, MonitoringAndEvaluation, Recommendation, SectionTabs, SubgroupConsiderations, SummaryOfFindings, Tabs, WhatItMeansForYou, WhoIsThisFor;

Assessment = require('../assessment');

Background = require('../background');

Decision = require('../decision');

DetailedJustification = require('../detailed_justification');

Implementation = require('../implementation');

Justification = require('../justification');

MonitoringAndEvaluation = require('../monitoring_and_evaluation');

Recommendation = require('../recommendation');

SubgroupConsiderations = require('../subgroup_considerations');

SummaryOfFindings = require('../summary_of_findings');

WhatItMeansForYou = require('../what_it_means_for_you');

WhoIsThisFor = require('../who_is_this_for');

Tabs = require('../common/tabs');

SectionTabs = React.createClass({displayName: "SectionTabs",
  render: function() {
    var sections;
    sections = this.props.sections;
    return React.createElement("div", null, React.createElement("h4", null, this.props.sections.get('id')), React.createElement("div", {
      "className": "main-tabs"
    }, React.createElement(Tabs.List, {
      "initiallyActive": 0
    }, sections["delete"]('recommendation').map((function(_this) {
      return function(section, index) {
        return _this.createTab(section.get('name'), index);
      };
    })(this)))));
  },
  createTab: function(name, component) {
    return React.createElement(Tabs.Tab, {
      "caption": name
    }, this.renderComponent(component));
  },
  renderComponent: function(component) {
    switch (component) {
      case 'assessment':
        return React.createElement(Assessment, {
          "attachments": this.props.attachments,
          "currentEditable": this.props.currentEditable,
          "actions": this.props.actions,
          "isEditable": this.props.isEditable,
          "assessment": this.props.assessment,
          "assessmentSections": this.props.assessmentSections
        });
      case 'background':
        return React.createElement(Background, {
          "currentEditable": this.props.currentEditable,
          "actions": this.props.actions,
          "question": this.props.question,
          "sections": this.props.sections,
          "backgroundDetailSections": this.props.backgroundDetailSections,
          "isEditable": this.props.isEditable,
          "type": this.props.type
        });
      case 'decision':
        return React.createElement(Decision, {
          "currentEditable": this.props.currentEditable,
          "actions": this.props.actions,
          "isEditable": this.props.isEditable,
          "decision": this.props.sections.get('decision')
        });
      case 'detailedJustification':
        return React.createElement(DetailedJustification, {
          "actions": this.props.actions,
          "currentEditable": this.props.currentEditable,
          "isEditable": this.props.isEditable,
          "conclusions": this.props.conclusions.get('justification')
        });
      case 'implementation':
        return React.createElement(Implementation, {
          "actions": this.props.actions,
          "currentEditable": this.props.currentEditable,
          "isEditable": this.props.isEditable,
          "conclusions": this.props.conclusions.get('implementationConsiderations')
        });
      case 'justification':
        return React.createElement(Justification, {
          "type": this.props.type,
          "actions": this.props.actions,
          "currentEditable": this.props.currentEditable,
          "isEditable": this.props.isEditable,
          "conclusions": this.props.conclusions.get('justification')
        });
      case 'monitoringAndEvaluation':
        return React.createElement(MonitoringAndEvaluation, {
          "actions": this.props.actions,
          "currentEditable": this.props.currentEditable,
          "isEditable": this.props.isEditable,
          "conclusions": this.props.conclusions.get('monitoringAndEvaluation')
        });
      case 'recommendation':
        return React.createElement(Recommendation, {
          "actions": this.props.actions,
          "currentEditable": this.props.currentEditable,
          "isEditable": this.props.isEditable,
          "conclusions": this.props.conclusions.get('recommendation')
        });
      case 'subgroupConsiderations':
        return React.createElement(SubgroupConsiderations, {
          "actions": this.props.actions,
          "currentEditable": this.props.currentEditable,
          "isEditable": this.props.isEditable,
          "conclusions": this.props.conclusions.get('subgroupConsiderations')
        });
      case 'summaryOfFindings':
        return React.createElement(SummaryOfFindings, {
          "isEditable": false,
          "isofData": this.props.isofData
        });
      case 'whatItMeansForYou':
        return React.createElement(WhatItMeansForYou, {
          "actions": this.props.actions,
          "currentEditable": this.props.currentEditable,
          "isEditable": this.props.isEditable,
          "whatItMeansForYou": this.props.sections.get('whatItMeansForYou')
        });
      case 'whoIsThisFor':
        return React.createElement(WhoIsThisFor, {
          "actions": this.props.actions,
          "currentEditable": this.props.currentEditable,
          "isEditable": this.props.isEditable,
          "whoIsThisFor": this.props.sections.get('whoIsThisFor')
        });
    }
  }
});

module.exports = SectionTabs;


},{"../assessment":1,"../background":2,"../common/tabs":14,"../decision":15,"../detailed_justification":16,"../implementation":17,"../justification":20,"../monitoring_and_evaluation":21,"../recommendation":24,"../subgroup_considerations":26,"../summary_of_findings":28,"../what_it_means_for_you":29,"../who_is_this_for":30}],13:[function(require,module,exports){
var StaticHtmlContent;

StaticHtmlContent = React.createClass({displayName: "StaticHtmlContent",
  createMarkup: function(content) {
    return {
      __html: content
    };
  },
  _setImagesSrc: function() {
    var regExp, srcSetter;
    regExp = /<img[^>]+db-image="([\S]+)"[^>]+src=("")/g;
    srcSetter = (function(_this) {
      return function(imageString, imageName, srcValue) {
        return imageString.replace(srcValue, _this._getDataURL(imageName));
      };
    })(this);
    if (this.props.content) {
      return this.props.content.replace(regExp, srcSetter);
    } else {
      return "";
    }
  },
  _getDataURL: function(imageName) {
    var content_type, data, imageAttachment, ref;
    imageAttachment = this.props.attachments.get(imageName);
    if (!imageAttachment) {
      return "\"\"";
    }
    ref = imageAttachment.toJS(), content_type = ref.content_type, data = ref.data;
    if (content_type && data) {
      return "\"data:" + content_type + ";base64," + data + "\"";
    } else {
      return "\"\"";
    }
  },
  render: function() {
    var content;
    content = this._setImagesSrc();
    return React.createElement("div", {
      "className": "content static",
      "dangerouslySetInnerHTML": this.createMarkup(content)
    });
  }
});

module.exports = StaticHtmlContent;


},{}],14:[function(require,module,exports){
var List, Tab;

List = React.createClass({displayName: "List",
  getInitialState: function() {
    var ref;
    return {
      active: (ref = this.props.initiallyActive) != null ? ref : 0
    };
  },
  _switchTab: function(idx) {
    return (function(_this) {
      return function(e) {
        e.preventDefault();
        return _this.setState({
          active: idx
        });
      };
    })(this);
  },
  _tabChanged: function(from, to) {
    var base;
    return typeof (base = this.props).tabChanged === "function" ? base.tabChanged({
      from: from,
      to: to,
      activeTabComponent: _(this._getActivePanel()).compact()[0]
    }) : void 0;
  },
  _getActivePanel: function() {
    return React.Children.map(this.props.children, (function(_this) {
      return function(child, idx) {
        if (idx === _this.state.active) {
          return React.cloneElement(child);
        }
      };
    })(this));
  },
  _getMenu: function() {
    return React.Children.map(this.props.children, (function(_this) {
      return function(child, idx) {
        var activeClass;
        child = React.cloneElement(child);
        if (idx === _this.state.active) {
          activeClass = 'active';
        }
        return React.createElement("li", {
          "className": activeClass
        }, React.createElement("a", {
          "href": '#',
          "onClick": _this._switchTab(idx)
        }, child.props.caption));
      };
    })(this));
  },
  componentDidMount: function() {
    return this._tabChanged(null, this.state.active);
  },
  componentDidUpdate: function(prevProps, prevState) {
    if (prevState.active !== this.state.active) {
      return this._tabChanged(prevState.active, this.state.active);
    }
  },
  render: function() {
    return React.createElement("div", {
      "className": 'tabpanel'
    }, React.createElement("div", {
      "className": "tab-headers"
    }, React.createElement("ul", {
      "className": 'nav nav-tabs',
      "role": 'tablist'
    }, this._getMenu())), this._getActivePanel());
  }
});

Tab = React.createClass({displayName: "Tab",
  propTypes: {
    caption: React.PropTypes.string.isRequired,
    children: React.PropTypes.oneOfType([React.PropTypes.arrayOf(React.PropTypes.element), React.PropTypes.element])
  },
  render: function() {
    return React.createElement("div", {
      "className": 'tab-content active'
    }, this.props.children);
  }
});

module.exports = {
  List: List,
  Tab: Tab
};


},{}],15:[function(require,module,exports){
var ContentEditableWithButtons, Decision, PureRenderMixin, RadioInput, StaticHtmlContent;

RadioInput = epReactComponents.common.RadioInput;

ContentEditableWithButtons = epReactComponents.common.ContentEditableWithButtons;

StaticHtmlContent = require('./common/static_html_content');

PureRenderMixin = React.addons.PureRenderMixin;

Decision = React.createClass({displayName: "Decision",
  mixins: [PureRenderMixin],
  decisionChanged: function(e) {
    return this.props.actions.changeAndSaveDecision(e.currentTarget.value);
  },
  render: function() {
    var contentClasses, ref;
    contentClasses = classNames('content-bordered', {
      'editable': this.props.isEditable
    });
    return React.createElement("div", {
      "className": "decision"
    }, React.createElement("h4", {
      "className": "section-title"
    }, this.props.decision.get('description')), (this.props.decision.get('options') ? React.createElement("div", {
      "className": "radio-buttons"
    }, React.createElement("ul", null, (ref = this.props.decision) != null ? ref.get('options').map((function(_this) {
      return function(option) {
        var checked;
        checked = option.get('value') === _this.props.decision.get('selectedOption');
        return React.createElement("li", {
          "key": option.get('value')
        }, React.createElement("label", {
          "htmlFor": option.get('value')
        }, (_this.props.isEditable ? React.createElement(RadioInput, {
          "id": option.get('value'),
          "name": "decision",
          "value": option.get('value'),
          "onChange": _this.decisionChanged,
          "defaultChecked": checked
        }) : React.createElement("input", {
          "id": option.get('value'),
          "type": "radio",
          "value": option.get('value'),
          "name": "decision",
          "checked": checked,
          "readOnly": true
        })), React.createElement("span", null, option.get('text'))));
      };
    })(this)) : void 0)) : void 0), React.createElement("div", {
      "className": contentClasses
    }, (this.props.isEditable ? React.createElement(ContentEditableWithButtons, {
      "id": "decision#policymakers",
      "actions": this.props.actions,
      "currentEditable": this.props.currentEditable,
      "content": this.props.decision.get('content')
    }) : React.createElement(StaticHtmlContent, {
      "content": this.props.decision.get('content')
    }))));
  }
});

module.exports = Decision;


},{"./common/static_html_content":13}],16:[function(require,module,exports){
var ContentEditableWithButtons, DetailedJustification, PureRenderMixin, StaticHtmlContent;

ContentEditableWithButtons = epReactComponents.common.ContentEditableWithButtons;

StaticHtmlContent = require('./common/static_html_content');

PureRenderMixin = React.addons.PureRenderMixin;

DetailedJustification = React.createClass({displayName: "DetailedJustification",
  mixins: [PureRenderMixin],
  render: function() {
    var contentClasses, ref, ref1, ref2, ref3, ref4, ref5;
    contentClasses = classNames('content-bordered', {
      'editable': this.props.isEditable
    });
    return React.createElement("div", null, React.createElement("h4", {
      "className": "section-title"
    }, (ref = this.props.conclusions) != null ? (ref1 = ref.get('details')) != null ? ref1.get('description') : void 0 : void 0), React.createElement("div", {
      "className": contentClasses
    }, (this.props.isEditable ? React.createElement(ContentEditableWithButtons, {
      "id": "justification#conclusions#details",
      "actions": this.props.actions,
      "currentEditable": this.props.currentEditable,
      "content": (ref2 = this.props.conclusions) != null ? (ref3 = ref2.get('details')) != null ? ref3.get('content') : void 0 : void 0,
      "onApply": this.onApply
    }) : React.createElement(StaticHtmlContent, {
      "content": (ref4 = this.props.conclusions) != null ? (ref5 = ref4.get('details')) != null ? ref5.get('content') : void 0 : void 0
    }))));
  }
});

module.exports = DetailedJustification;


},{"./common/static_html_content":13}],17:[function(require,module,exports){
var DetailedContentEditable, Implementation, PureRenderMixin;

DetailedContentEditable = require('./common/detailed_content_editable');

PureRenderMixin = React.addons.PureRenderMixin;

Implementation = React.createClass({displayName: "Implementation",
  mixins: [PureRenderMixin],
  render: function() {
    var ref, ref1, ref2, ref3, ref4, ref5;
    return React.createElement("div", null, React.createElement(DetailedContentEditable, {
      "id": "implementationConsiderations#conclusions#details",
      "actions": this.props.actions,
      "currentEditable": this.props.currentEditable,
      "isEditable": this.props.isEditable,
      "detailLabel": (ref = this.props.conclusions) != null ? (ref1 = ref.get('details')) != null ? ref1.get('description') : void 0 : void 0,
      "detailContent": (ref2 = this.props.conclusions) != null ? (ref3 = ref2.get('details')) != null ? ref3.get('content') : void 0 : void 0,
      "contentLabel": (ref4 = this.props.conclusions) != null ? ref4.get('description') : void 0,
      "content": (ref5 = this.props.conclusions) != null ? ref5.get('content') : void 0
    }));
  }
});

module.exports = Implementation;


},{"./common/detailed_content_editable":6}],18:[function(require,module,exports){
var ISoFDx;

module.exports = ISoFDx = (function() {
  function ISoFDx(container, data) {
    this.container = container;
    this.data = data;
    angular.module('isofDxApp').value('isofData', this.data);
    angular.element(this.container).ready((function(_this) {
      return function() {
        if (!angular.element(_this.container).scope()) {
          return angular.bootstrap(_this.container, ['isofDxApp']);
        }
      };
    })(this));
  }

  return ISoFDx;

})();


},{}],19:[function(require,module,exports){
var ISoFTx;

module.exports = ISoFTx = (function() {
  function ISoFTx(container, data) {
    this.container = container;
    this.data = data;
    angular.module('isofTxApp').value('isofData', this.data);
    angular.element(this.container).ready((function(_this) {
      return function() {
        return angular.bootstrap(_this.container, ['isofTxApp']);
      };
    })(this));
  }

  return ISoFTx;

})();


},{}],20:[function(require,module,exports){
var ContentEditableWithButtons, DetailedContentEditable, Justification, PureRenderMixin, StaticHtmlContent;

ContentEditableWithButtons = epReactComponents.common.ContentEditableWithButtons;

DetailedContentEditable = require('./common/detailed_content_editable');

StaticHtmlContent = require('./common/static_html_content');

PureRenderMixin = React.addons.PureRenderMixin;

Justification = React.createClass({displayName: "Justification",
  mixins: [PureRenderMixin],
  render: function() {
    var contentClasses, ref, ref1, ref2, ref3, ref4, ref5, ref6, ref7, ref8;
    contentClasses = classNames('content-bordered', {
      'editable': this.props.isEditable
    });
    return React.createElement("div", null, (this.props.type === 'clinicians' ? React.createElement("div", null, React.createElement("h4", {
      "className": "section-title"
    }, (ref = this.props.conclusions) != null ? ref.get('description') : void 0), React.createElement("div", {
      "className": contentClasses
    }, (this.props.isEditable ? React.createElement(ContentEditableWithButtons, {
      "id": "justification#conclusions",
      "actions": this.props.actions,
      "currentEditable": this.props.currentEditable,
      "content": (ref1 = this.props.conclusions) != null ? ref1.get('content') : void 0,
      "onApply": this.onApply
    }) : React.createElement(StaticHtmlContent, {
      "content": (ref2 = this.props.conclusions) != null ? ref2.get('content') : void 0
    })))) : React.createElement(DetailedContentEditable, {
      "actions": this.props.actions,
      "id": "justification#conclusions#details",
      "currentEditable": this.props.currentEditable,
      "isEditable": this.props.isEditable,
      "contentLabel": (ref3 = this.props.conclusions) != null ? ref3.get('description') : void 0,
      "content": (ref4 = this.props.conclusions) != null ? ref4.get('content') : void 0,
      "detailLabel": (ref5 = this.props.conclusions) != null ? (ref6 = ref5.get('details')) != null ? ref6.get('description') : void 0 : void 0,
      "detailContent": (ref7 = this.props.conclusions) != null ? (ref8 = ref7.get('details')) != null ? ref8.get('content') : void 0 : void 0,
      "onApply": this.onApply
    })));
  }
});

module.exports = Justification;


},{"./common/detailed_content_editable":6,"./common/static_html_content":13}],21:[function(require,module,exports){
var DetailedContentEditable, MonitoringAndEvaluation, PureRenderMixin;

DetailedContentEditable = require('./common/detailed_content_editable');

PureRenderMixin = React.addons.PureRenderMixin;

MonitoringAndEvaluation = React.createClass({displayName: "MonitoringAndEvaluation",
  mixins: [PureRenderMixin],
  render: function() {
    var ref, ref1;
    return React.createElement("div", null, React.createElement(DetailedContentEditable, {
      "id": "monitoringAndEvaluation#conclusions#details",
      "actions": this.props.actions,
      "isEditable": this.props.isEditable,
      "currentEditable": this.props.currentEditable,
      "detailLabel": (ref = this.props.conclusions.get('details')) != null ? ref.get('description') : void 0,
      "detailContent": (ref1 = this.props.conclusions.get('details')) != null ? ref1.get('content') : void 0,
      "contentLabel": this.props.conclusions.get('description'),
      "content": this.props.conclusions.get('content')
    }));
  }
});

module.exports = MonitoringAndEvaluation;


},{"./common/detailed_content_editable":6}],22:[function(require,module,exports){
var Patients, SectionTabs;

SectionTabs = require('../common/section_tabs');

Patients = React.createClass({displayName: "Patients",
  render: function() {
    return React.createElement(SectionTabs, {
      "currentEditable": this.props.currentEditable,
      "actions": this.props.actions,
      "isEditable": this.props.isEditable,
      "sections": this.props.sections,
      "conclusions": this.props.conclusions,
      "defaultTab": 'justification',
      "type": "patients"
    });
  }
});

module.exports = Patients;


},{"../common/section_tabs":12}],23:[function(require,module,exports){
var Policymakers, SectionTabs;

SectionTabs = require('../common/section_tabs');

Policymakers = React.createClass({displayName: "Policymakers",
  render: function() {
    return React.createElement(SectionTabs, {
      "attachments": this.props.attachments,
      "currentEditable": this.props.currentEditable,
      "actions": this.props.actions,
      "question": this.props.question,
      "isEditable": this.props.isEditable,
      "sections": this.props.sections,
      "assessment": this.props.assessment,
      "assessmentSections": this.props.assessmentSections,
      "conclusions": this.props.conclusions,
      "backgroundDetailSections": this.props.backgroundDetailSections,
      "defaultTab": 'assessment',
      "type": "policymakers"
    });
  }
});

module.exports = Policymakers;


},{"../common/section_tabs":12}],24:[function(require,module,exports){
var DetailedContentEditable, PureRenderMixin, Recommendation;

DetailedContentEditable = require('./common/detailed_content_editable');

PureRenderMixin = React.addons.PureRenderMixin;

Recommendation = React.createClass({displayName: "Recommendation",
  mixins: [PureRenderMixin],
  getInitialState: function() {
    var ref, ref1, ref2, ref3, ref4, ref5, ref6, ref7, ref8, ref9;
    return {
      detailLabel: (ref = (ref1 = this.props.conclusions) != null ? (ref2 = ref1.get('detailedRecommendation')) != null ? ref2.get('description') : void 0 : void 0) != null ? ref : "",
      detailContent: (ref3 = (ref4 = this.props.conclusions) != null ? (ref5 = ref4.get('detailedRecomendation')) != null ? ref5.get('content') : void 0 : void 0) != null ? ref3 : "",
      contentLabel: (ref6 = (ref7 = this.props.conclusions) != null ? ref7.get('description') : void 0) != null ? ref6 : "",
      content: (ref8 = (ref9 = this.props.conclusions) != null ? ref9.get('content') : void 0) != null ? ref8 : ""
    };
  },
  render: function() {
    return React.createElement("div", null, React.createElement(DetailedContentEditable, {
      "actions": this.props.actions,
      "currentEditable": this.props.currentEditable,
      "isEditable": this.props.isEditable,
      "detailLabel": this.state.detailLabel,
      "detailContent": this.state.detailContent,
      "contentLabel": this.state.contentLabel,
      "content": this.state.content
    }));
  }
});

module.exports = Recommendation;


},{"./common/detailed_content_editable":6}],25:[function(require,module,exports){
var ContentEditableWithButtons, PureRenderMixin, RecommendationHeader, StaticHtmlContent;

ContentEditableWithButtons = epReactComponents.common.ContentEditableWithButtons;

StaticHtmlContent = require('./common/static_html_content');

PureRenderMixin = React.addons.PureRenderMixin;

RecommendationHeader = React.createClass({displayName: "RecommendationHeader",
  mixins: [PureRenderMixin],
  render: function() {
    var ref, ref1, ref2, ref3, ref4, signClassnames;
    signClassnames = classNames("sign", {
      'strongly-recommended-for': (ref = this.props.selectedOption) === 'recommend' || ref === 'cover'
    }, {
      'strongly-recommended-against': (ref1 = this.props.selectedOption) === 'recommend_against' || ref1 === 'do_not_cover'
    }, {
      'conditional-recommended-for': (ref2 = this.props.selectedOption) === 'suggest' || ref2 === 'restricted_coverage'
    }, {
      'conditional-recommended-against': (ref3 = this.props.selectedOption) === 'suggest_against' || ref3 === 'cover_with_evidence'
    }, {
      'conditional-either': (ref4 = this.props.selectedOption) === 'suggest_either' || ref4 === 'cover_with_price'
    });
    return React.createElement("div", {
      "className": "recommendation-header"
    }, React.createElement("div", {
      "className": signClassnames
    }), (this.props.isEditable && this.props.presentationType === 'clinicians' ? React.createElement(ContentEditableWithButtons, {
      "id": "recommendation#conclusions",
      "currentEditable": this.props.currentEditable,
      "actions": this.props.actions,
      "content": this.props.content
    }) : React.createElement(StaticHtmlContent, {
      "content": this.props.content
    })), React.createElement("div", {
      "className": "clearfix"
    }));
  }
});

module.exports = RecommendationHeader;


},{"./common/static_html_content":13}],26:[function(require,module,exports){
var ContentEditableWithButtons, PureRenderMixin, StaticHtmlContent, SubgroupConsiderations;

ContentEditableWithButtons = epReactComponents.common.ContentEditableWithButtons;

StaticHtmlContent = require('./common/static_html_content');

PureRenderMixin = React.addons.PureRenderMix;

SubgroupConsiderations = React.createClass({displayName: "SubgroupConsiderations",
  mixins: [PureRenderMixin],
  getInitialState: function() {
    var ref, ref1, ref2, ref3;
    return {
      contentLabel: (ref = (ref1 = this.props.conclusions) != null ? ref1.get('description') : void 0) != null ? ref : "",
      content: (ref2 = (ref3 = this.props.conclusions) != null ? ref3.get('content') : void 0) != null ? ref2 : ""
    };
  },
  render: function() {
    var contentClasses;
    contentClasses = classNames('content-bordered', {
      'editable': this.props.isEditable
    });
    return React.createElement("div", null, React.createElement("h4", {
      "className": "section-title"
    }, this.props.conclusions.get('description')), React.createElement("div", {
      "className": contentClasses
    }, (this.props.isEditable ? React.createElement(ContentEditableWithButtons, {
      "id": "subgroupConsiderations#conclusions",
      "currentEditable": this.props.currentEditable,
      "actions": this.props.actions,
      "content": this.props.conclusions.get('content')
    }) : React.createElement(StaticHtmlContent, {
      "content": this.props.conclusions.get('content')
    }))));
  }
});

module.exports = SubgroupConsiderations;


},{"./common/static_html_content":13}],27:[function(require,module,exports){
var AdditionalConsiderations, ContentEditableWithButtons, Decision, Judgement, ResearchEvidences, Subgroup, Tabs;

Judgement = require('../common/judgement');

ResearchEvidences = require('../common/research_evidences');

AdditionalConsiderations = require('../common/additional_considerations');

Decision = require('../common/decision');

ContentEditableWithButtons = epReactComponents.common.ContentEditableWithButtons;

Tabs = require('../common/tabs');

Subgroup = React.createClass({displayName: "Subgroup",
  render: function() {
    var ref;
    return React.createElement("div", null, React.createElement("div", {
      "className": "top-tabs"
    }, React.createElement(Tabs.List, {
      "initiallyActive": 0
    }, React.createElement(Tabs.Tab, {
      "caption": 'Judgement'
    }, React.createElement("div", {
      "className": "inner-tab-content"
    }, React.createElement(Judgement, {
      "criteria": this.props.criteria
    }))), React.createElement(Tabs.Tab, {
      "caption": 'Research Evidences'
    }, React.createElement(ResearchEvidences, {
      "content": this.props.researchEvidences.get('content'),
      "attachments": this.props.attachments
    })), React.createElement(Tabs.Tab, {
      "caption": 'Additional Considerations'
    }, React.createElement(AdditionalConsiderations, {
      "content": this.props.additionalConsiderations.get('content')
    })))), React.createElement("div", {
      "className": "bottom-tabs"
    }, React.createElement(Tabs.List, {
      "initiallyActive": 0
    }, React.createElement(Tabs.Tab, {
      "caption": 'Decision'
    }, React.createElement("div", {
      "className": "inner-tab-content"
    }, React.createElement(Decision, {
      "sectionId": this.props.index,
      "criteria": this.props.criteria,
      "actions": this.props.actions
    }))), React.createElement(Tabs.Tab, {
      "caption": 'Comments'
    }, React.createElement(ContentEditableWithButtons, {
      "id": this.props.index + "#criteria",
      "currentEditable": this.props.currentEditable,
      "actions": this.props.actions,
      "content": (ref = this.props.criteria.get('decision')) != null ? ref.get('comments') : void 0
    })))));
  }
});

module.exports = Subgroup;


},{"../common/additional_considerations":4,"../common/decision":5,"../common/judgement":7,"../common/research_evidences":11,"../common/tabs":14}],28:[function(require,module,exports){
var ISoFDx, ISoFTx, SummaryOfFindings;

ISoFDx = require('./isof-dx');

ISoFTx = require('./isof-tx');

SummaryOfFindings = React.createClass({displayName: "SummaryOfFindings",
  render: function() {
    return React.createElement("div", null, React.createElement("div", {
      "id": "isof-dx-content"
    }, React.createElement("diagnosis", null)), React.createElement("div", {
      "id": "isof-tx-content"
    }, React.createElement("isof-table", {
      "editable": "false"
    })));
  },
  componentDidMount: function() {
    if (this.props.isofData.isDiagnostic) {
      $('#isof-dx-content').addClass('isof-dx container');
      if (this.props.isofData.get('ok')) {
        return new ISoFDx('#isof-dx-content', this.props.isofData.toJS());
      }
    } else {
      $('#isof-tx-content').addClass('isof-tx container');
      if (this.props.isofData.get('ok')) {
        return new ISoFTx('#isof-tx-content', this.props.isofData.toJS());
      }
    }
  }
});

module.exports = SummaryOfFindings;


},{"./isof-dx":18,"./isof-tx":19}],29:[function(require,module,exports){
var ContentEditableWithButtons, PureRenderMixin, StaticHtmlContent, WhatItMeansForYou;

ContentEditableWithButtons = epReactComponents.common.ContentEditableWithButtons;

StaticHtmlContent = require('./common/static_html_content');

PureRenderMixin = React.addons.PureRenderMix;

WhatItMeansForYou = React.createClass({displayName: "WhatItMeansForYou",
  mixins: [PureRenderMixin],
  render: function() {
    var contentClasses;
    contentClasses = classNames('content-bordered', {
      'editable': this.props.isEditable
    });
    return React.createElement("div", null, React.createElement("h4", {
      "className": "section-title"
    }, this.props.whatItMeansForYou.get('name')), React.createElement("div", {
      "className": contentClasses
    }, (this.props.isEditable ? React.createElement(ContentEditableWithButtons, {
      "id": "whatItMeansForYou#patients",
      "currentEditable": this.props.currentEditable,
      "actions": this.props.actions,
      "content": this.props.whatItMeansForYou.get('content')
    }) : React.createElement(StaticHtmlContent, {
      "content": this.props.whatItMeansForYou.get('content')
    }))));
  }
});

module.exports = WhatItMeansForYou;


},{"./common/static_html_content":13}],30:[function(require,module,exports){
var ContentEditableWithButtons, PureRenderMixin, StaticHtmlContent, WhoIsThisFor;

ContentEditableWithButtons = epReactComponents.common.ContentEditableWithButtons;

StaticHtmlContent = require('./common/static_html_content');

PureRenderMixin = React.addons.PureRenderMix;

WhoIsThisFor = React.createClass({displayName: "WhoIsThisFor",
  mixins: [PureRenderMixin],
  render: function() {
    var contentClasses;
    contentClasses = classNames('content-bordered', {
      'editable': this.props.isEditable
    });
    return React.createElement("div", null, React.createElement("h4", {
      "className": "section-title"
    }, this.props.whoIsThisFor.get('name')), React.createElement("div", {
      "className": contentClasses
    }, (this.props.isEditable ? React.createElement(ContentEditableWithButtons, {
      "id": "whoIsThisFor#patients",
      "currentEditable": this.props.currentEditable,
      "actions": this.props.actions,
      "content": this.props.whoIsThisFor.get('content')
    }) : React.createElement(StaticHtmlContent, {
      "content": this.props.whoIsThisFor.get('content')
    }))));
  }
});

module.exports = WhoIsThisFor;


},{"./common/static_html_content":13}],31:[function(require,module,exports){
var Clinicians, Components, Patients, Policymakers, Presentations, RecommendationHeader;

Components = window.epReactComponents;

Clinicians = require('./components/clinicians/clinicians');

Patients = require('./components/patients/patients');

Policymakers = require('./components/policymakers/policymakers');

RecommendationHeader = require('./components/recommendation_header');

Presentations = React.createClass({displayName: "Presentations",
  render: function() {
    var content, selectedOption;
    selectedOption = this.props.recommendation.selectedOption;
    content = this.props.recommendation.content;
    return React.createElement("div", {
      "className": "presentations"
    }, React.createElement(RecommendationHeader, {
      "presentationTitle": this.props.presentationTitle,
      "currentEditable": this.props.currentEditable,
      "actions": this.props.actions,
      "isEditable": this.props.isEditable,
      "presentationType": this.props.presentationType,
      "content": content,
      "selectedOption": selectedOption
    }), React.createElement("div", {
      "className": "presentations"
    }, (this.props.presentationType === 'policymakers' ? React.createElement(Policymakers, {
      "attachments": this.props.attachments,
      "currentEditable": this.props.currentEditable,
      "actions": this.props.actions,
      "isEditable": this.props.isEditable,
      "sections": this.props.policymakers,
      "assessment": this.props.assessment,
      "assessmentSections": this.props.assessmentSections,
      "question": this.props.question,
      "conclusions": this.props.conclusions,
      "backgroundDetailSections": this.props.backgroundDetailSections
    }) : void 0), (this.props.presentationType === 'clinicians' ? React.createElement(Clinicians, {
      "attachments": this.props.attachments,
      "currentEditable": this.props.currentEditable,
      "actions": this.props.actions,
      "isEditable": this.props.isEditable,
      "question": this.props.question,
      "conclusions": this.props.conclusions,
      "sections": this.props.clinicians,
      "isofData": this.props.isofData
    }) : void 0), (this.props.presentationType === 'patients' ? React.createElement(Patients, {
      "attachments": this.props.attachments,
      "currentEditable": this.props.currentEditable,
      "actions": this.props.actions,
      "isEditable": this.props.isEditable,
      "conclusions": this.props.conclusions,
      "sections": this.props.patients
    }) : void 0)));
  }
});

module.exports = Presentations;


},{"./components/clinicians/clinicians":3,"./components/patients/patients":22,"./components/policymakers/policymakers":23,"./components/recommendation_header":25}]},{},[31])(31)
});