(function(f){if(typeof exports==="object"&&typeof module!=="undefined"){module.exports=f()}else if(typeof define==="function"&&define.amd){define([],f)}else{var g;if(typeof window!=="undefined"){g=window}else if(typeof global!=="undefined"){g=global}else if(typeof self!=="undefined"){g=self}else{g=this}g.epReactComponents = f()}})(function(){var define,module,exports;return (function e(t,n,r){function s(o,u){if(!n[o]){if(!t[o]){var a=typeof require=="function"&&require;if(!u&&a)return a(o,!0);if(i)return i(o,!0);var f=new Error("Cannot find module '"+o+"'");throw f.code="MODULE_NOT_FOUND",f}var l=n[o]={exports:{}};t[o][0].call(l.exports,function(e){var n=t[o][1][e];return s(n?n:e)},l,l.exports,e,t,n,r)}return n[o].exports}var i=typeof require=="function"&&require;for(var o=0;o<r.length;o++)s(r[o]);return s})({1:[function(require,module,exports){
var Button, CollapsiblePanel, Components, ContentEditable, ContentEditableWithButtons, EditableItem, EditableItemsList, InputWithValidation, LabelWithInput, LabelWithText, List, Modal, RadioInput, ReactMediumEditor, Select, Tabs, Translation;

Button = require('./components/common/button');

CollapsiblePanel = require('./components/common/collapsible_panel');

ContentEditable = require('./components/common/content_editable');

ContentEditableWithButtons = require('./components/common/content_editable_with_buttons');

EditableItem = require('./components/common/editable_item');

EditableItemsList = require('./components/common/editable_items_list');

InputWithValidation = require('./components/common/input_with_validation');

LabelWithInput = require('./components/common/label_with_input');

LabelWithText = require('./components/common/label_with_text');

List = require('./components/common/list');

Modal = require('./components/common/modal');

RadioInput = require('./components/common/radio_input');

ReactMediumEditor = require('./components/mixins/react_medium_editor');

Select = require('./components/common/select');

Tabs = require('./components/common/tabs');

Translation = require('./components/mixins/translation');

Components = {
  common: {
    Button: Button,
    CollapsiblePanel: CollapsiblePanel,
    ContentEditable: ContentEditable,
    ContentEditableWithButtons: ContentEditableWithButtons,
    EditableItem: EditableItem,
    EditableItemsList: EditableItemsList,
    InputWithValidation: InputWithValidation,
    LabelWithInput: LabelWithInput,
    LabelWithText: LabelWithText,
    List: List,
    Modal: Modal,
    RadioInput: RadioInput,
    Select: Select,
    Tabs: Tabs
  },
  mixins: {
    ReactMediumEditor: ReactMediumEditor,
    Translation: Translation
  }
};

module.exports = Components;


},{"./components/common/button":2,"./components/common/collapsible_panel":3,"./components/common/content_editable":4,"./components/common/content_editable_with_buttons":5,"./components/common/editable_item":6,"./components/common/editable_items_list":7,"./components/common/input_with_validation":8,"./components/common/label_with_input":9,"./components/common/label_with_text":10,"./components/common/list":11,"./components/common/modal":12,"./components/common/radio_input":13,"./components/common/select":14,"./components/common/tabs":15,"./components/mixins/react_medium_editor":16,"./components/mixins/translation":17}],2:[function(require,module,exports){
var Button;

Button = React.createClass({displayName: "Button",
  render: function() {
    return React.createElement("button", React.__spread({}, this.props), this.props.label);
  }
});

module.exports = Button;


},{}],3:[function(require,module,exports){
var CollapsiblePanel;

CollapsiblePanel = React.createClass({displayName: "CollapsiblePanel",
  getInitialState: function() {
    var ref, ref1;
    return {
      collapsed: (ref = (ref1 = this.props) != null ? ref1.collapsed : void 0) != null ? ref : false
    };
  },
  toggle: function() {
    return this.setState({
      collapsed: !this.state.collapsed
    });
  },
  renderPanel: function(panelClassName) {
    if (this.state.collapsed) {
      return React.createElement("div", {
        "className": panelClassName
      });
    } else {
      return React.createElement("div", {
        "className": panelClassName
      }, this.props.children);
    }
  },
  render: function() {
    var triggerClassName;
    triggerClassName = this.state.collapsed ? 'collapsed' : 'expanded';
    return React.createElement("div", {
      "className": this.props.className
    }, React.createElement("p", {
      "className": triggerClassName,
      "onClick": this.toggle
    }, React.createElement("span", {
      "className": "icon"
    }), this.props.title), this.renderPanel(triggerClassName));
  }
});

module.exports = CollapsiblePanel;


},{}],4:[function(require,module,exports){
var ContentEditable, MediumEditor;

MediumEditor = require('../mixins/react_medium_editor');

ContentEditable = React.createClass({displayName: "ContentEditable",
  mixins: [MediumEditor],
  getDefaultProps: function() {
    return {
      contentEditable: true,
      className: "content"
    };
  },
  shouldComponentUpdate: function(nextProps, nextState) {
    return nextProps.html !== this.getDOMNode().innerHTML || nextProps.contentEditable !== this.props.contentEditable;
  },
  componentDidMount: function() {
    return this._focus();
  },
  componentDidUpdate: function() {
    if (this.props.html !== this.getDOMNode().innerHTML) {
      this.getDOMNode().innerHTML = this.props.html;
    }
    return this._focus();
  },
  _focus: function() {
    if (this.props.contentEditable) {
      return this.getDOMNode().focus();
    }
  },
  render: function() {
    return React.createElement("div", React.__spread({}, this.props, {
      "dangerouslySetInnerHTML": {
        __html: this.props.html
      }
    }));
  }
});

module.exports = ContentEditable;


},{"../mixins/react_medium_editor":16}],5:[function(require,module,exports){
var Button, ContentEditable, ContentEditableWithButtons;

ContentEditable = require('./content_editable');

Button = require('./button');

ContentEditableWithButtons = React.createClass({
  displayName: "ContentEditableWithButtons",
  propTypes: {
    actions: React.PropTypes.object.isRequired,
    id: React.PropTypes.string.isRequired,
    currentEditable: React.PropTypes.string.isRequired
  },
  componentWillUnmount: function() {
    if (this.props.isActiveEditable) {
      return this._close();
    }
  },
  componentDidMount: function() {
    return this.initialHTML = this.props.content;
  },
  componentWillReceiveProps: function(nextProps) {
    var contentWasUpdated, currentHTML;
    if (this._activeEditable() && !this._activeEditable(nextProps) && !this.cancelled) {
      currentHTML = this.refs.contentEditable.getDOMNode().innerHTML;
      contentWasUpdated = this.initialHTML !== currentHTML;
      if (contentWasUpdated) {
        this.initialHTML = currentHTML;
        return this.props.actions.saveContentEditable();
      }
    } else {
      return this.cancelled = false;
    }
  },
  onClick: function() {
    return this.props.actions.enableContentEditable(this.props.id);
  },
  handleInput: function(e) {
    if (this._updateContentEditableContent == null) {
      this._updateContentEditableContent = _.debounce(this.props.actions.updateContentEditableContent, 200);
    }
    return this._updateContentEditableContent(this.props.id, e.target.innerHTML);
  },
  cancel: function() {
    this.props.actions.updateContentEditableContent(this.props.id, this.initialHTML);
    this.cancelled = true;
    return this._close();
  },
  apply: function() {
    return this._close();
  },
  _close: function() {
    return this.props.actions.disableContentEditable();
  },
  _activeEditable: function(props) {
    if (props == null) {
      props = this.props;
    }
    return props.currentEditable === props.id;
  },
  render: function() {
    return React.createElement("div", {
      "className": "editor-container"
    }, (this._activeEditable() ? React.createElement("div", null, React.createElement(ContentEditable, {
      "ref": "contentEditable",
      "html": this.props.content,
      "onInput": this.handleInput
    }), React.createElement("div", {
      "className": "top-buttons row"
    }, this.props.topButtons), React.createElement("div", {
      "className": "row buttons-react"
    }, React.createElement("div", {
      "className": "col-6"
    }, React.createElement(Button, {
      "className": "cancel btn btn-block",
      "label": "Cancel",
      "onMouseDown": this.cancel
    })), React.createElement("div", {
      "className": "col-6"
    }, React.createElement(Button, {
      "className": "apply btn btn-block btn-success",
      "label": "Apply",
      "onMouseDown": this.apply
    })))) : React.createElement(ContentEditable, {
      "contentEditable": false,
      "onClick": this.onClick,
      "html": this.props.content
    })));
  }
});

module.exports = ContentEditableWithButtons;


},{"./button":2,"./content_editable":4}],6:[function(require,module,exports){
var Button, EditableItem, Translation;

Translation = require('../mixins/translation');

Button = require('./button');

EditableItem = {
  Editing: React.createClass({
    propTypes: {
      children: React.PropTypes.element.isRequired
    },
    mixins: [Translation('')],
    displayName: 'Editing',
    render: function() {
      return React.createElement("div", null, React.Children.only(this.props.children), React.createElement("div", {
        "className": "side-buttons"
      }, React.createElement(Button, {
        "className": "save",
        "title": this.i18n('actions.save_changes_title'),
        "onClick": this.props.save
      }), React.createElement(Button, {
        "className": "delete",
        "title": this.i18n('delete'),
        "onClick": this.props["delete"]
      })));
    }
  }),
  Static: React.createClass({
    propTypes: {
      children: React.PropTypes.element.isRequired
    },
    mixins: [Translation('')],
    displayName: 'Static',
    render: function() {
      return React.createElement("div", null, React.createElement(Button, {
        "className": "edit",
        "title": this.i18n('edit'),
        "onClick": this.props.edit
      }), React.createElement("div", {
        "className": "caption"
      }, React.Children.only(this.props.children)));
    }
  }),
  Item: React.createClass({
    propTypes: {
      children: React.PropTypes.array.isRequired,
      edit: React.PropTypes.func.isRequired,
      save: React.PropTypes.func.isRequired,
      "delete": React.PropTypes.func.isRequired,
      cancel: React.PropTypes.func.isRequired
    },
    displayName: 'EditableItem',
    save: function() {
      return this.props.save(this.props.item);
    },
    "delete": function() {
      return this.props["delete"](this.props.itemKey);
    },
    edit: function() {
      return this.props.edit(this.props.itemKey);
    },
    cancel: function() {
      return this.props.cancel(this.props.itemKey);
    },
    getEditingComponent: function() {
      return React.Children.map(this.props.children, (function(_this) {
        return function(child) {
          if (child.type.displayName === 'Editing') {
            return React.cloneElement(child, {
              save: _this.save,
              "delete": _this["delete"]
            });
          }
        };
      })(this));
    },
    getStaticComponent: function() {
      return React.Children.map(this.props.children, (function(_this) {
        return function(child) {
          if (child.type.displayName === 'Static') {
            return React.cloneElement(child, {
              edit: _this.edit
            });
          }
        };
      })(this));
    },
    render: function() {
      var liClasses;
      liClasses = classNames({
        expanded: this.props.editing
      });
      return React.createElement("li", {
        "className": liClasses
      }, (this.props.editing ? this.getEditingComponent() : this.getStaticComponent()));
    }
  })
};

module.exports = EditableItem;


},{"../mixins/translation":17,"./button":2}],7:[function(require,module,exports){
var EditableItemsList, List;

List = require('./list');

EditableItemsList = React.createClass({
  displayName: 'EditableItemsList',
  edit: function(itemKey) {
    return this.props.actions.edit(itemKey, this.props.itemsMap.get(this.props.editingKey));
  },
  save: function(item) {
    return this.props.actions.save(item);
  },
  "delete": function(itemKey) {
    return this.props.actions["delete"](itemKey);
  },
  cancel: function(itemKey) {
    return this.save(this.props.itemsMap.get(itemKey));
  },
  render: function() {
    var ref;
    return React.createElement(List, {
      "ordered": (ref = this.props.ordered) != null ? ref : true
    }, this.props.itemsMap.map((function(_this) {
      return function(item, key) {
        var ref1;
        return React.createElement(_this.props.ItemComponent, {
          "key": key,
          "itemKey": key,
          "item": item,
          "editing": _this.props.editingKey === key,
          "save": _this.save,
          "delete": _this["delete"],
          "edit": _this.edit,
          "cancel": _this.cancel,
          "itemValidationErrors": (ref1 = _this.props.validationErrors) != null ? ref1.get(key) : void 0
        });
      };
    })(this)).toList());
  }
});

module.exports = EditableItemsList;


},{"./list":11}],8:[function(require,module,exports){
var InputWithValidation;

InputWithValidation = React.createClass({displayName: "InputWithValidation",
  render: function() {
    var className;
    className = classNames(this.props.className, {
      error: this.props.errorMsg
    });
    return React.createElement("input", React.__spread({}, this.props, {
      "className": className
    }));
  }
});

module.exports = InputWithValidation;


},{}],9:[function(require,module,exports){
var LabelWithInput;

LabelWithInput = React.createClass({displayName: "LabelWithInput",
  getInitialState: function() {
    return {
      value: this.props.value,
      staticContent: true
    };
  },
  switchToEditMode: function() {
    return this.setState({
      staticContent: false
    }, (function(_this) {
      return function() {
        return React.findDOMNode(_this.refs.inputField).focus();
      };
    })(this));
  },
  apply: function(e) {
    this.props.onApply(e.target.value);
    return this.setState({
      staticContent: true
    });
  },
  onBlur: function(e) {
    return this.apply(e);
  },
  handleChange: function(e) {
    return this.setState({
      value: e.target.value
    });
  },
  render: function() {
    return React.createElement("div", {
      "className": "label-with-input"
    }, React.createElement("label", null, this.props.title), (this.state.staticContent ? React.createElement("span", {
      "onClick": this.switchToEditMode
    }, this.state.value) : React.createElement("input", {
      "ref": "inputField",
      "type": "text",
      "onBlur": this.onBlur,
      "value": this.state.value,
      "onChange": this.handleChange
    })));
  }
});

module.exports = LabelWithInput;


},{}],10:[function(require,module,exports){
var LabelWithText;

LabelWithText = React.createClass({displayName: "LabelWithText",
  render: function() {
    return React.createElement("div", {
      "className": this.props.classes
    }, React.createElement("label", null, this.props.title), React.createElement("span", null, this.props.value));
  }
});

module.exports = LabelWithText;


},{}],11:[function(require,module,exports){
var List;

List = React.createClass({
  displayName: 'List',
  render: function() {
    if (this.props.ordered) {
      return React.createElement("ol", {
        "className": "standard-list"
      }, this.props.children);
    } else {
      return React.createElement("ul", {
        "className": "standard-list"
      }, this.props.children);
    }
  }
});

module.exports = List;


},{}],12:[function(require,module,exports){
var Modal;

Modal = React.createClass({
  displayName: 'Modal',
  propTypes: {
    isOpen: React.PropTypes.bool.isRequired,
    onClose: React.PropTypes.func.isRequired,
    closeButton: React.PropTypes.bool
  },
  render: function() {
    ReactModal.setAppElement(document.body);
    return React.createElement(ReactModal, {
      "isOpen": this.props.isOpen,
      "className": this.props.className
    }, (this.props.title ? React.createElement("h2", null, this.props.title) : void 0), (this.props.closeButton ? React.createElement("div", {
      "className": "modal-close",
      "onClick": this.props.onClose
    }) : void 0), this.props.children);
  }
});

module.exports = Modal;


},{}],13:[function(require,module,exports){
var RadioInput;

RadioInput = React.createClass({displayName: "RadioInput",
  render: function() {
    return React.createElement("input", React.__spread({}, this.props, {
      "type": "radio"
    }));
  }
});

module.exports = RadioInput;


},{}],14:[function(require,module,exports){
var Select;

Select = React.createClass({displayName: "Select",
  onChange: function(e) {
    return this.props.onChange(e.currentTarget.value);
  },
  render: function() {
    return React.createElement("select", {
      "onChange": this.onChange,
      "name": this.props.name,
      "id": this.props.id,
      "value": this.props.selected
    }, _(this.props.options).map(function(opt) {
      var ref, value;
      if (_(opt).isObject()) {
        value = (ref = opt.value) != null ? ref : opt.text;
        return React.createElement("option", {
          "key": value,
          "disabled": opt.disabled,
          "value": value
        }, opt.text);
      } else {
        return React.createElement("option", {
          "key": opt,
          "value": opt
        }, opt);
      }
    }));
  }
});

module.exports = Select;


},{}],15:[function(require,module,exports){
var List, Tab;

List = React.createClass({displayName: "List",
  getInitialState: function() {
    var ref;
    return {
      active: (ref = this.props.initiallyActive) != null ? ref : 0
    };
  },
  _switchTab: function(idx) {
    return (function(_this) {
      return function(e) {
        e.preventDefault();
        return _this.setState({
          active: idx
        });
      };
    })(this);
  },
  _tabChanged: function(from, to) {
    var base;
    return typeof (base = this.props).tabChanged === "function" ? base.tabChanged({
      from: from,
      to: to,
      activeTabComponent: _(this._getActivePanel()).compact()[0]
    }) : void 0;
  },
  _getActivePanel: function() {
    return React.Children.map(this.props.children, (function(_this) {
      return function(child, idx) {
        if (idx === _this.state.active) {
          return React.cloneElement(child);
        }
      };
    })(this));
  },
  _getMenu: function() {
    return React.Children.map(this.props.children, (function(_this) {
      return function(child, idx) {
        var activeClass;
        child = React.cloneElement(child);
        if (idx === _this.state.active) {
          activeClass = 'active';
        }
        return React.createElement("li", {
          "className": activeClass
        }, React.createElement("a", {
          "href": '#',
          "onClick": _this._switchTab(idx)
        }, child.props.caption));
      };
    })(this));
  },
  componentDidMount: function() {
    return this._tabChanged(null, this.state.active);
  },
  componentDidUpdate: function(prevProps, prevState) {
    if (prevState.active !== this.state.active) {
      return this._tabChanged(prevState.active, this.state.active);
    }
  },
  render: function() {
    return React.createElement("div", {
      "className": 'tabpanel'
    }, React.createElement("ul", {
      "className": 'nav nav-tabs',
      "role": 'tablist'
    }, this._getMenu()), this._getActivePanel());
  }
});

Tab = React.createClass({displayName: "Tab",
  propTypes: {
    caption: React.PropTypes.string.isRequired,
    children: React.PropTypes.oneOfType([React.PropTypes.arrayOf(React.PropTypes.element), React.PropTypes.element])
  },
  render: function() {
    return React.createElement("div", {
      "className": 'tab-content active'
    }, this.props.children);
  }
});

module.exports = {
  List: List,
  Tab: Tab
};


},{}],16:[function(require,module,exports){
var ReactMediumEditorMixin;

ReactMediumEditorMixin = {
  displayName: 'ReactMediumEditor',
  getDefaultProps: function() {
    return {
      mediumEditorOptions: {
        disablePlaceholders: true,
        forcePlainText: false,
        cleanPastedHTML: true
      }
    };
  },
  componentDidMount: function() {
    if (this.props.contentEditable && this.props.withEditor) {
      return this.medium = new MediumEditor(this.getDOMNode(), this.props.mediumEditorOptions);
    }
  },
  componentWillUnmount: function() {
    if (this.props.contentEditable && this.medium) {
      return this.medium.destroy();
    }
  },
  disableMediumEditor: function() {
    return this.medium.destroy();
  }
};

module.exports = ReactMediumEditorMixin;


},{}],17:[function(require,module,exports){
var Translation;

Translation = function(namespace) {
  if (namespace == null) {
    namespace = '';
  }
  return {
    i18n: function(key, params) {
      var keyWithNamespace;
      if (params == null) {
        params = {};
      }
      keyWithNamespace = _.string.startsWith(key, '/') ? key.slice(1) : namespace.length ? namespace + "." + key : key;
      return $.t(keyWithNamespace, params);
    }
  };
};

module.exports = Translation;


},{}]},{},[1])(1)
});
